import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { styled } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as React from "react";
import FileUpload from "react-material-file-upload";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { GlobalContext } from "../../context/GlobalContext";
import { initailStatePartner } from "../../context/types";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/index";
import { getBase64 } from "../../utils/getBase64";
import { cnpjMask, phoneMask } from "../../utils/masks";
import PartnerForm from "./components/PartnerForm";
import * as S from "./style";
dayjs.extend(customParseFormat);

const Tab = styled(TabUnstyled)`
  color: #0092d4;
  font-size: 1rem;
  font-weight: bold;
  padding: 16px 40px;
  border: none;
  border-radius: 7px;
  background-color: #fff;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    background-color: #f4f4f4;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)(() => `margin-bottom: 41px;`);
const statusCustomer = [
  "analisando",
  "bloqueado",
  "aprovado",
  "reprovado",
  "incompleto",
];
const initialFormStateOneStep = {
  registration_status: "analisando",
  finance_status: "analisando",
  name: " ",
  company_name: "",
  email: "",
  cnpj: "",
  client_type: "PJ",
  phone: "",
  whatsapp: "",
};

const initialFormStateBankInfo = {
  customer_id: "",
  bank_id: 1,
  agency: "",
  account: "",
  holder_name: "",
  document: "",
};

const disabledTab = {
  color: "#c3c2c2",
  cursor: "unset",
};

function RegisterShopJudicial() {
  const { partnerForm, setPartnerForm, customerId, setCustomerId } =
    React.useContext(GlobalContext);
  const MySwal = withReactContent(Swal);
  const { state, pathname } = useLocation();

  const [fileContract, setFileContract] = React.useState([]);
  const [fileAddress, setFileAddress] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(initialFormStateOneStep);
  const [address, setAddress] = React.useState([]);
  const [count, setCounter] = React.useState(2);
  const [partners, setPartners] = React.useState([1]);
  const [formBankInfo, setFormBankInfo] = React.useState(
    initialFormStateBankInfo
  );
  const [bankList, setBankList] = React.useState([]);
  const [taxTables, setTaxTables] = React.useState({});
  const [taxInfo, setTaxInfo] = React.useState({
    credit_card: 1,
    boleto: 1,
    pix: 1,
  });

  React.useEffect(() => {
    loadBankList();
    if (state) {
      getCustomerInfo();
      setCustomerId(state.customer_id);
      loadTaxTables();
    }
  }, []);
  async function loadBankList() {
    const res = await api.get("/v1/customers/bank/list");
    setBankList(res.data);
  }
  async function loadTaxTables() {
    const res = await api.get("/v1/gateway/admin/fees_tariffs/list/tables");
    setTaxTables(res.data);
  }
  function setInput(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setForm((form) => ({ ...form, ...newValue }));
    };
  }

  function setInputBankInfo(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setFormBankInfo((formBankInfo) => ({
        ...formBankInfo,
        ...newValue,
      }));
    };
  }

  function addNewPartner() {
    setCounter(count + 1);
    partners.push(count);
    setPartnerForm((state) => [...state, initailStatePartner[0]]);
  }

  function RemoveNewPartner() {
    partners.pop();
    partnerForm.pop();
    setCounter(count - 1);
  }

  const requestType = state ? "update" : "register";
  async function getCustomerInfo() {
    const formType = pathname.split("-")[2];

    try {
      setLoading(true);
      const { data } = await api.post(
        "/v1/gateway/admin/customers/list/detail",
        {
          customer_id: state.customer_id,
        }
      );

      setForm({
        registration_status: data.registration_status,
        finance_status: data.finance_status,
        name: data.name,
        company_name: data.company_name,
        email: data.email,
        cnpj: data.cnpj,
        client_type: "PJ",
        phone: data.phone,
      });
      setAddress({
        id: data.address.id,
        zip_code: data.address.zip_code,
        state: data.address.state,
        city: data.address.city,
        street: data.address.street,
        number: data.address.number,
        neighborhood: data.address.neighborhood,
        complement: data.address.complement,
      });
      setPartnerForm(data.partners);
      setFormBankInfo({
        id: data.bank_account.id,
        bank_id: data.bank_account.bank_id,
        agency: data.bank_account.agency,
        account: data.bank_account.account,
        holder_name: data.bank_account.holder_name,
        document: data.bank_account.document,
      });

      data?.documents.map((document) => {
        if (document.type === "contrato_social") {
          setFileContract(document?.url);
        }
        if (document.type === "comprovante_endereco_empresa") {
          setFileAddress(document?.url);
        }
      });
      loadCustomerTaxes();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }
  async function loadCustomerTaxes() {
    const taxesRes = await api.post(
      "/v1/gateway/admin/customers/fees_tariffs/list",
      {
        customer_id: state.customer_id,
      }
    );
    setTaxInfo({
      credit_card: taxesRes.data?.credit_card?.billing_category,
      boleto: taxesRes?.data?.boleto?.billing_category,
      pix: taxesRes?.data.pix?.billing_category,
    });
  }

  async function registerCustomers() {
    const body = {
      customer_id: requestType === "update" ? customerId : null,
      registration_status: form.registration_status,
      finance_status: form.finance_status,
      name: form.name,
      company_name: form.company_name,
      email: form.email,
      cnpj: String(form.cnpj).replace(/\D/g, ""),
      client_type: "PJ",
      phone: String(form.phone).replace(/\D/g, ""),
      address: {
        zip_code: address.zip_code,
        state: address.state,
        city: address.city,
        street: address.street,
        number: address.number,
        neighborhood: address.neighborhood,
        complement: address.complement || "",
      },
    };

    const hasEmptyBody = Object.values(body).some((item) => item === "");
    const hasEmptyBodyAddress = Object.values(body.address).some(
      (item) => item === undefined
    );
    if (hasEmptyBody || hasEmptyBodyAddress) {
      MySwal.fire({
        icon: "error",
        title: "Algo deu errado",
        text: "Preencha todos os campos!",
        customClass: {
          confirmButton: "btn btn-error",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const res =
        requestType === "update"
          ? await api.put(`v1/gateway/admin/customers/update`, body)
          : await api.post(`v1/gateway/admin/customers/register`, body);

      setCustomerId(res.data.customer_id);
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Dados do cliente cadastrado!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.errors?.email) {
        MySwal.fire({
          icon: "warning",
          title: "Email já utilizado!",
          text: "Tente novamente...",
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Erro!",
          text: "Tente novamente...",
        });
      }
    }
  }

  async function registerPartner() {
    let hasEmptyPartnerForm = null;
    let hasEmptyPartnerAddressForm = null;

    partnerForm.forEach((partner) => {
      hasEmptyPartnerForm = Object.values(partner).some(
        (item) => item === "" || item === undefined
      );
      hasEmptyPartnerAddressForm = Object.values(partner.address).some(
        (item) => item === undefined
      );
    });

    if (hasEmptyPartnerAddressForm || hasEmptyPartnerForm) {
      MySwal.fire({
        icon: "error",
        title: "Algo deu errado",
        text: "Preencha todos os campos!",
        customClass: {
          confirmButton: "btn btn-error",
        },
      });
      return;
    }

    try {
      setLoading(true);

      await partnerForm.forEach(async (partner) => {
        if (partner.birthday.includes("/")) {
          partner.birthday = dayjs(partner.birthday, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
        }

        partner.partner_id !== null
          ? await api.put(`v1/gateway/admin/customers/partners/update`, partner)
          : await api.post(
              `v1/gateway/admin/customers/partners/register`,
              partner
            );

        MySwal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Dados do sócio cadastrado!",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  async function registerTaxes() {
    try {
      const body = {
        customer_id: state.customer_id,
        billing_method_list: [
          {
            billing_method: "credit_card",
            billing_category: taxInfo.credit_card,
          },
          {
            billing_method: "boleto",
            billing_category: taxInfo.boleto,
          },
          {
            billing_method: "pix",
            billing_category: taxInfo.pix,
          },
        ],
      };
      await api.post(`/v1/gateway/admin/customers/billing-customer`, body);
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Taxas atualizas!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      loadCustomerTaxes();
      setLoading(false);
    } catch (err) {
      console.log(err);

    }
  }
  async function registerBank() {
    const has_empty = Object.entries(formBankInfo).map((entries) => {
      if (entries[0] !== "customer_id" && !!entries[1] === false) {
        return true;
      }
      return false;
    });

    if (has_empty.some((value) => value === true)) {
      return MySwal.fire({
        icon: "error",
        title: "algo deu errado",
        text: "Preencha todos os campos!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    }

    const body = { ...formBankInfo, customer_id: customerId };

    try {
      setLoading(true);

      formBankInfo.customer_id
        ? await api.put(`v1/gateway/admin/customers/bank/update`, body)
        : await api.post(`v1/gateway/admin/customers/bank/register`, body);

      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Dados bancarios cadastrados!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  const searchPostalCode = async (e) => {
    setAddress({ ...address, zip_code: e });

    const replacedCEP = e.replace("-", "").replace("_", "");
    if (replacedCEP.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${replacedCEP.replace(/\D/g, "")}/json/`)
        .then((res) => {
          if (!res.data.erro) {
            setAddress({
              zip_code: String(res.data.cep),
              state: String(res.data.uf),
              city: String(res.data.localidade),
              street: String(res.data.logradouro),
              number: "",
              neighborhood: String(res.data.bairro),
              complement: String(res.data.complemento),
            });
          }
        });
    }
  };

  async function registerDocument() {
    const requestType = state ? "update" : "register";
    try {
      setLoading(true);
      let contrato_social = await getBase64(fileAddress[0]);
      let comprovante_endereco_empresa = await getBase64(fileAddress[0]);

      const body = {
        customer_id: customerId,
        contrato_social: contrato_social,
        comprovante_endereco_empresa: comprovante_endereco_empresa,
      };

      requestType === "update"
        ? await api.put(`v1/gateway/admin/customers/document/update`, body)
        : await api.post(`v1/gateway/admin/customers/document/register`, body);

      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Documentos cadastrados!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <MainLayout title={"Cadastro de Lojistas"}>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Dados do cliente</Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Informações societária
          </Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Dados bancários
          </Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Documentos enviados
          </Tab>
          <Tab
            sx={(!customerId && disabledTab) || requestType === "register"}
            disabled={!customerId || requestType === "register"}
          >
            Configurar Taxas
          </Tab>
        </TabsList>
        <TabPanel value={0}>
          <S.Title>Dados Pessoais</S.Title>

          <S.Form>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="SituacaoCadastral">Situação Cadastral</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, registration_status: e.target.value })
                  }
                  value={form.registration_status}
                >
                  {statusCustomer.map((status) => (
                    <option value={status}>{status}</option>
                  ))}
                </S.Select>
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="SituacaoFinanceira">Situação Financeira</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, finance_status: e.target.value })
                  }
                  value={form.finance_status}
                >
                  {statusCustomer.map((status) => (
                    <option value={status}>{status}</option>
                  ))}
                </S.Select>
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="RazaoSocial">Razão Social</Label>
              <Input
                name="name"
                onChange={setInput("name")}
                value={form.name}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Nome Fantasia">Nome Fantasia</Label>
              <Input
                name="company_name"
                onChange={setInput("company_name")}
                value={form.company_name}
              />
            </S.fullInput>
            <S.divWrapper>
              <Label htmlFor="CNPJ">CNPJ</Label>
              <Input
                name="CNPJ"
                maxLength={18}
                onChange={setInput("cnpj")}
                value={cnpjMask(form.cnpj)}
              />
            </S.divWrapper>
            <S.fullInput>
              <Label htmlFor="E-mail">E-mail</Label>
              <Input
                name="email"
                onChange={setInput("email")}
                value={form.email}
              />
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="TelefoneContato">Telefone de contato</Label>
                <Input
                  name="phone"
                  onChange={setInput("phone")}
                  value={phoneMask(form?.phone || "")}
                />
              </S.divWrapper>
            </S.Column>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="CEP">CEP</Label>
                <Input
                  name="zip_code"
                  onChange={(e) => {
                    searchPostalCode(e.target.value);
                  }}
                  value={address.zip_code}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="Cidade">Cidade</Label>
                <Input
                  name="city"
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                  value={address.city}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="UF">Estado</Label>
                <Input
                  name="state"
                  onChange={(e) =>
                    setAddress({ ...address, state: e.target.value })
                  }
                  value={address.state}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Endereco">Endereço</Label>
              <Input
                name="street"
                onChange={(e) =>
                  setAddress({ ...address, street: e.target.value })
                }
                value={address.street}
              />
            </S.fullInput>

            <S.divWrapper>
              <Label htmlFor="Numero">Número</Label>
              <Input
                name="number"
                type="number"
                onChange={(e) =>
                  setAddress({ ...address, number: e.target.value })
                }
                value={address.number}
              />
            </S.divWrapper>

            <S.fullInput>
              <Label htmlFor="Bairro">Bairro</Label>
              <Input
                name="neighborhood"
                onChange={(e) =>
                  setAddress({ ...address, neighborhood: e.target.value })
                }
                value={address.neighborhood}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Complemento">Complemento</Label>
              <Input
                name="complement"
                onChange={(e) =>
                  setAddress({ ...address, complement: e.target.value })
                }
                value={address.complement}
              />
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerCustomers();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  window.location.href = "/listagem-lojista";
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>

        <TabPanel value={1}>
          <S.ButtonWrapper>
            <S.ButtonHeader onClick={() => addNewPartner()}>
              <AddIcon />
              Novo Sócio
            </S.ButtonHeader>
            {partners.length >= 2 ? (
              <S.ButtonHeader onClick={() => RemoveNewPartner()}>
                <RemoveIcon />
                Retirar um sócio
              </S.ButtonHeader>
            ) : (
              ""
            )}
          </S.ButtonWrapper>

          {partnerForm.map((partner, idx) => (
            <PartnerForm
              key={idx}
              index={idx}
              position={idx + 1}
              id={partner?.id || null}
            />
          ))}

          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerPartner();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                window.location.href = "/listagem-lojista";
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </TabPanel>
        <TabPanel value={2}>
          <S.Title>Dados Bancários</S.Title>
          <S.Form>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Banco</Label>
              <S.Select
                onChange={setInputBankInfo("bank_id")}
                value={formBankInfo.bank_id}
              >
                <option value="">Selecionar banco</option>
                {bankList.map((bank) => (
                  <option value={bank.id}>{bank?.name}</option>
                ))}
              </S.Select>
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="Agência">Agência</Label>
                <Input
                  name="agency"
                  onChange={setInputBankInfo("agency")}
                  value={formBankInfo.agency}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Conta Corrente">Conta Corrente</Label>
                <Input
                  name="account"
                  onChange={setInputBankInfo("account")}
                  value={formBankInfo.account}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="CNPJ">CNPJ</Label>
                <Input
                  name="document"
                  maxLength={18}
                  onChange={setInputBankInfo("document")}
                  value={cnpjMask(formBankInfo.document || "")}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Nome do titular">Nome do titular</Label>
              <Input
                name="holder_name"
                onChange={setInputBankInfo("holder_name")}
                value={formBankInfo.holder_name}
              />
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerBank();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  window.location.href = "/listagem-lojista";
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>
        <TabPanel value={3}>
          <S.uploadWrapper>
            <Label htmlFor="CCS">Cópia Contrato Social</Label>
            {Array.isArray(fileContract) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileContract}
                onChange={setFileContract}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileContract}
                />
                <a href={fileContract} target="_blank" rel="noreferrer">
                  {fileContract}
                </a>
              </>
            )}
          </S.uploadWrapper>
          <S.uploadWrapper>
            <Label htmlFor="ComprovanteEndereco">
              Comprovante de Endereço da Empresa
            </Label>

            {Array.isArray(fileAddress) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileAddress}
                onChange={setFileAddress}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileAddress}
                />
                <a href={fileAddress} target="_blank" rel="noreferrer">
                  {fileAddress}
                </a>
              </>
            )}
          </S.uploadWrapper>
          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerDocument();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                window.location.href = "/listagem-lojista";
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </TabPanel>
        <TabPanel value={4}>
          <S.Title>Taxas do Cliente</S.Title>
          <S.Form>
            <S.fullInput>
              <Label htmlFor="">Tabela de taxas Cartão de crédito</Label>
              <S.Select
                onChange={(e) =>
                  setTaxInfo((oldTaxInfo) => ({
                    ...oldTaxInfo,
                    credit_card: e.target.value,
                  }))
                }
                value={taxInfo.credit_card}
              >
                {taxTables?.credit_card?.map((table) => (
                  <option value={table?.id}>
                    {table?.billing_category_name}
                  </option>
                ))}
              </S.Select>
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Tabela de taxa Boleto</Label>
              <S.Select
                onChange={(e) =>
                  setTaxInfo((oldTaxInfo) => ({
                    ...oldTaxInfo,
                    boleto: e.target.value,
                  }))
                }
                value={taxInfo.boleto}
              >
                {taxTables?.boleto?.map((table) => (
                  <option value={table?.id}>
                    {table?.billing_category_name}
                  </option>
                ))}
              </S.Select>
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Tabela de taxa Pix</Label>
              <S.Select
                onChange={(e) =>
                  setTaxInfo((oldTaxInfo) => ({
                    ...oldTaxInfo,
                    pix: e.target.value,
                  }))
                }
                value={taxInfo.pix}
              >
                {taxTables?.pix?.map((table) => (
                  <option value={table?.id}>
                    {table?.billing_category_name}
                  </option>
                ))}
              </S.Select>
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerTaxes();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  window.location.href = "/listagem-lojista";
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>
      </TabsUnstyled>
    </MainLayout>
  );
}

export default RegisterShopJudicial;
