import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import Label from "../../components/Label";
import MainLayout from "../../layouts/MainLayout";
import ModalDetails from "./components/modal";
import * as S from "./style";
import Cloud from "../../assets/images/iconCloud.svg";
import api from "../../services/index";
import { useEffect } from "react";
import { useState } from "react";
import * as XLSX from "xlsx";
import { internalStatus } from "../../utils/functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function HistoryTransactions() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const MySwal = withReactContent(Swal);

  const [body, setBody] = useState({
    type: "",
    content: "",
    start: "",
    end: "",
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function openModalDetails(row) {
    setData(row);
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getData() {
    try {
      setLoading(true);
      const res = await api.post(
        "/v1/gateway/admin/transaction/history/list",
        body
      );
      setHistory(res.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function HandleExport() {
    setLoading(true);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(history);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "HistoricoTransacoes.xlsx");
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MainLayout title="Histórico de transações">
      <S.Header>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, start: e.target.value })}
        >
          <Label>Data Inicial</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, end: e.target.value })}
        >
          <Label>Data Final</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="value">Valor</option>
          <option value="cpf">CPF</option>
          <option value="status">Status</option>
          <option value="email">Email</option>
          <option value="name">Lojista</option>
        </S.Select>
        <S.Input
          placeholder="Busque pelo nome"
          onChange={(e) => setBody({ ...body, content: e.target.value })}
        />
        <S.Button disabled={loading} onClick={() => getData()}>
          Pesquisar
        </S.Button>
      </S.Header>

      <S.HeaderButtons>
        <S.ButtonHeader disabled={loading} onClick={() => HandleExport()}>
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonHeader>
      </S.HeaderButtons>

      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Código da transação</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Valor</TableCell>
                <TableCell>Lojista</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {history.map((history) => (
                <TableRow
                  key={history.date}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="history">
                    {history.date}
                  </TableCell>
                  <TableCell>{history.transaction_code}</TableCell>
                  <TableCell>
                    <S.Icon status={internalStatus(history.status)}>
                      <S.TextIcon status={internalStatus(history.status)}>
                        {internalStatus(history.status)}
                      </S.TextIcon>
                    </S.Icon>
                  </TableCell>
                  <TableCell>
                    {" "}
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(history.amount)}
                  </TableCell>
                  <TableCell>{history?.customer?.name}</TableCell>
                  <TableCell onClick={() => openModalDetails(history)}>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      style={{ padding: 0 }}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <MenuItem>
                        <ZoomInOutlinedIcon style={{ marginRight: 5 }} />
                        Detalhes
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {modalIsOpen ? (
        <ModalDetails
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
        />
      ) : (
        ""
      )}
    </MainLayout>
  );
}

export default HistoryTransactions;
