import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { TextField } from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import * as React from "react";
import FileUpload from "react-material-file-upload";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Input from "../../components/Input";
import Label from "../../components/Label";
import { GlobalContext } from "../../context/GlobalContext";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/index";
import { getBase64 } from "../../utils/getBase64";
import { cpfMask, dateMask, phoneMask, rgMask } from "../../utils/masks";
import * as S from "./style";
dayjs.extend(customParseFormat);

const Tab = styled(TabUnstyled)`
  color: #0092d4;
  font-size: 1rem;
  font-weight: bold;
  padding: 16px 40px;
  border: none;
  border-radius: 7px;
  background-color: #fff;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    background-color: #f4f4f4;
  }
`;

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)(() => `margin-bottom: 41px;`);

const initialFormStateOneStep = {
  registration_status: "analisando",
  finance_status: "analisando",
  name: "",
  phone: "",
  email: "",
  birthday: "",
  genre: "M",
  marital_status: "solteiro",
  cpf: "",
  rg: "",
  nationality: "",
  profession: "",
  mother_name: "",
  father_name: "",
  client_type: "PF",
  observation: "",
  customer_id: "",
};

const initialAddress = {
  zip_code: "",
  state: "",
  city: "",
  street: "",
  number: "",
  neighborhood: "",
  complement: "",
};

const initialFormStateBankInfo = {
  customer_id: "",
  bank_id: "",
  agency: "",
  account: "",
  document: "",
  holder_name: "",
};

const disabledTab = {
  color: "#c3c2c2",
  cursor: "unset",
};

const statusCustomer = [
  "analisando",
  "bloqueado",
  "aprovado",
  "reprovado",
  "incompleto",
];

function RegisterShopPhysical() {
  const { customerId, setCustomerId } = React.useContext(GlobalContext);
  const { state, pathname } = useLocation();

  const MySwal = withReactContent(Swal);

  const [fileCNHFront, setFileCNHFront] = React.useState([]);
  const [fileCNHBack, setFileCNHBack] = React.useState([]);
  const [fileEndereco, setFileEndereco] = React.useState([]);
  const [fileMarried, setFileMarried] = React.useState([]);
  const [fileSelfie, setFileSelfie] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [bankList, setBankList] = React.useState([]);
  const [form, setForm] = React.useState(initialFormStateOneStep);
  const [address, setAddress] = React.useState(initialAddress);

  const [taxTables, setTaxTables] = React.useState({});
  const [taxInfo, setTaxInfo] = React.useState({
    credit_card: 1,
    boleto: 1,
    pix: 1,
  });
  const [formBankInfo, setFormBankInfo] = React.useState(
    initialFormStateBankInfo
  );

  const requestType = state ? "update" : "register";

  React.useEffect(() => {
    loadBankList();
    if (state) {
      getCustomerInfo();
      setCustomerId(state.customer_id);
      loadTaxTables();
    }
  }, []);

  async function loadBankList() {
    const res = await api.get("/v1/customers/bank/list");
    setBankList(res.data);
  }

  async function loadTaxTables() {
    const res = await api.get("/v1/gateway/admin/fees_tariffs/list/tables");
    setTaxTables(res.data);
  }

  function setInput(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setForm((form) => ({ ...form, ...newValue }));
    };
  }

  function setInputBankInfo(inputName) {
    return (e) => {
      const newValue = { [inputName]: e.target.value };
      return setFormBankInfo((formBankInfo) => ({
        ...formBankInfo,
        ...newValue,
      }));
    };
  }

  async function getCustomerInfo() {
    const formType = pathname.split("-")[2];

    try {
      setLoading(true);
      const res = await api.post("/v1/gateway/admin/customers/list/detail", {
        customer_id: state.customer_id,
      });

      const data = formType === "pj" ? res.data : res.data;

      setForm({
        registration_status: data.registration_status,
        finance_status: data.finance_status,
        name: data.name,
        phone: data.phone,
        email: data.email,
        birthday: dayjs(data.birthday).format("DD/MM/YYYY"),
        genre: data.genre,
        marital_status: data.marital_status,
        rg: data.rg,
        cpf: data.cpf,
        nationality: data.nationality,
        profession: data.profession,
        mother_name: data.mother_name,
        father_name: data.father_name,
        client_type: "PF",
        observation: data.observation || undefined,
      });
      setAddress({
        id: data.address.id,
        zip_code: data.address.zip_code,
        state: data.address.state,
        city: data.address.city,
        street: data.address.street,
        number: data.address.number,
        neighborhood: data.address.neighborhood,
        complement: data.address.complement,
      });
      setFormBankInfo({
        // id: data.bank_account?.id,
        bank_id: data.bank_account?.bank_id || null,
        agency: data.bank_account?.agency,
        account: data.bank_account?.account,
        holder_name: data.bank_account?.holder_name,
        document: data.bank_account?.document,
      });

      data?.documents.map(async (document) => {
        if (document.type === "selfie") {
          setFileSelfie(document?.url);
        }
        if (document.type === "cnh_rg_frente") {
          setFileCNHFront(document?.url);
        }
        if (document.type === "cnh_rg_verso") {
          setFileCNHBack(document?.url);
        }
        if (document.type === "comprovante_residencia") {
          setFileEndereco(document?.url);
        }
        if (document.type === "certidao_casamento") {
          setFileMarried(document?.url);
        }
      });

      loadCustomerTaxes();

      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function loadCustomerTaxes() {
    const taxesRes = await api.post(
      "/v1/gateway/admin/customers/fees_tariffs/list",
      {
        customer_id: state.customer_id,
      }
    );
    setTaxInfo({
      credit_card: taxesRes.data?.credit_card?.billing_category,
      boleto: taxesRes?.data?.boleto?.billing_category,
      pix: taxesRes?.data.pix?.billing_category,
    });
  }

  async function registerCustomers() {
    const body = {
      customer_id: requestType === "update" ? customerId : null,
      registration_status: form.registration_status,
      finance_status: form.finance_status,
      name: form.name,
      phone: String(form.phone).replace(/\D/g, ""),
      email: form.email,
      birthday: dayjs(form.birthday, "DD/MM/YYYY").format("YYYY-MM-DD"),
      genre: form.genre,
      marital_status: form.marital_status,
      rg: String(form.rg).replace(/\D/g, ""),
      cpf: form.cpf,
      nationality: form.nationality,
      profession: form.profession,
      mother_name: form.mother_name,
      father_name: form.father_name,
      client_type: "PF",
      observation: form.observation || undefined,
      address: {
        zip_code: String(address.zip_code).replace("-", "").replace("_", ""),
        state: address.state,
        city: address.city,
        street: address.street,
        number: address.number,
        neighborhood: address.neighborhood,
        complement: address.complement || "",
      },
    };

    const hasEmptyBody = Object.values(body).some((item) => item === "");
    const hasEmptyBodyAddress = Object.values(body.address).some(
      (item) => item === undefined
    );

    if (hasEmptyBody || hasEmptyBodyAddress) {
      MySwal.fire({
        icon: "error",
        title: "Algo deu errado",
        text: "Preencha todos os campos!",
        customClass: {
          confirmButton: "btn btn-error",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const res =
        requestType === "update"
          ? await api.put(`v1/gateway/admin/customers/update`, body)
          : await api.post(`v1/gateway/admin/customers/register`, body);

      setCustomerId(res.data.customer_id);
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Dados do cliente cadastrado!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });

      setLoading(false);
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.errors?.email) {
        MySwal.fire({
          icon: "warning",
          title: "Email já utilizado!",
          text: "Tente novamente...",
        });
      } else {
        MySwal.fire({
          icon: "error",
          title: "Erro!",
          text: "Tente novamente...",
        });
      }
    }
  }
  async function registerTaxes() {
    try {
      const body = {
        customer_id: state.customer_id,
        billing_method_list: [
          {
            billing_method: "credit_card",
            billing_category: taxInfo.credit_card,
          },
          {
            billing_method: "boleto",
            billing_category: taxInfo.boleto,
          },
          {
            billing_method: "pix",
            billing_category: taxInfo.pix,
          },
        ],
      };
      await api.post(`/v1/gateway/admin/customers/billing-customer`, body);
      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Taxas atualizas!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      loadCustomerTaxes();
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function registerBank() {
    // const has_empty = Object.entries(formBankInfo).map((entries) => {
    //   if (entries[0] !== "customer_id" && !!entries[1] === false) {
    //     return true;
    //   }
    //   return false;
    // });

    // if (has_empty.some((value) => value === true)) {
    //   return MySwal.fire({
    //     icon: "error",
    //     title: "algo deu errado",
    //     text: "Preencha todos os campos!",
    //     customClass: {
    //       confirmButton: "btn btn-success",
    //     },
    //   });
    // }

    const body = { ...formBankInfo, customer_id: customerId };

    try {
      setLoading(true);
      formBankInfo.customer_id
        ? await api.put(`v1/gateway/admin/customers/bank/update`, body)
        : await api.post(`v1/gateway/admin/customers/bank/register`, body);

      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Dados bancarios cadastrados!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      setLoading(false);
      navigate("/listagem-lojista");
    } catch (err) {
      console.log(err);
    }
  }

  async function registerDocument() {
    try {
      setLoading(true);
      let cnh_rg_frente = await getBase64(fileCNHFront[0]);
      let cnh_rg_verso = await getBase64(fileCNHBack[0]);
      let selfie = await getBase64(fileSelfie[0]);
      let comprovante_residencia = await getBase64(fileEndereco[0]);
      let certidao_casamento = await getBase64(fileMarried[0]);
      const body = {
        customer_id: customerId,
        cnh_rg_frente: cnh_rg_frente,
        cnh_rg_verso: cnh_rg_verso,
        selfie: selfie,
        comprovante_residencia: comprovante_residencia,
        certidao_casamento: certidao_casamento,
      };
      requestType === "update"
        ? await api.put(`v1/gateway/admin/customers/document/update`, body)
        : await api.post(`v1/gateway/admin/customers/document/register`, body);

      MySwal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Documentos enviados!",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const navigate = useNavigate();

  const searchPostalCode = async (e) => {
    setAddress({ ...address, zip_code: e });

    const replacedCEP = e.replace("-", "").replace("_", "");
    if (replacedCEP.length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${replacedCEP.replace(/\D/g, "")}/json/`)
        .then((res) => {
          if (!res.data.erro) {
            setAddress({
              zip_code: String(res.data.cep),
              state: String(res.data.uf),
              city: String(res.data.localidade),
              street: String(res.data.logradouro),
              number: "",
              neighborhood: String(res.data.bairro),
              complement: String(res.data.complemento),
            });
          }
        });
    }
  };

    console.log('formbank', formBankInfo)

  return (
    <MainLayout title={"Cadastro de Lojistas"}>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Dados do cliente</Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Dados bancários
          </Tab>
          <Tab sx={!customerId && disabledTab} disabled={!customerId}>
            Documentos enviados
          </Tab>
          <Tab
            sx={(!customerId && disabledTab) || requestType === "register"}
            disabled={!customerId || requestType === "register"}
          >
            Configurar Taxas
          </Tab>
        </TabsList>
        <TabPanel value={0}>
          <S.Title>Dados do Cliente</S.Title>

          <S.Form>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="SituacaoCadastral">Situação Cadastral</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, registration_status: e.target.value })
                  }
                  value={form.registration_status}
                >
                  {statusCustomer.map((status) => (
                    <option value={status}>{status}</option>
                  ))}
                </S.Select>
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="SituacaoFinanceira">Situação Financeira</Label>

                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, finance_status: e.target.value })
                  }
                  value={form.finance_status}
                >
                  {statusCustomer.map((status) => (
                    <option value={status}>{status}</option>
                  ))}
                </S.Select>
              </S.divWrapper>
            </S.Column>
            <S.fullInput>
              <Label htmlFor="NomeCompleto">Nome completo</Label>
              <Input
                name="name"
                onChange={setInput("name")}
                value={form.name}
              />
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="Telefone">Telefone</Label>
                <Input
                  name="phone"
                  onChange={setInput("phone")}
                  value={phoneMask(form.phone)}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="E-mail">E-mail</Label>
              <Input
                name="email"
                onChange={setInput("email")}
                value={form.email}
              />
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="DataNascimento">Data Nascimento</Label>
                <Input
                  name="birthday"
                  onChange={setInput("birthday")}
                  value={dateMask(form.birthday)}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="Sexo">Genero</Label>
                <S.Select
                  onChange={(e) => setForm({ ...form, genre: e.target.value })}
                  value={form.genre}
                >
                  <option value="" selected>
                    ...
                  </option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </S.Select>
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Sexo">Estado Civil</Label>
                <S.Select
                  onChange={(e) =>
                    setForm({ ...form, marital_status: e.target.value })
                  }
                  value={form.marital_status}
                >
                  <option value="" selected>
                    ...
                  </option>
                  <option value="solteiro">Solteiro(a)</option>
                  <option value="casado">Casado(a)</option>
                  <option value="viuvo">Viúvo(a)</option>
                  <option value="divorciado">Divorciado(a)</option>
                </S.Select>
              </S.divWrapper>
            </S.Column>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="RG">RG</Label>
                <Input
                  name="rg"
                  onChange={setInput("rg")}
                  value={rgMask(form.rg)}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="cpf">CPF</Label>

                <Input
                  name="cpf"
                  onChange={setInput("cpf")}
                  value={cpfMask(form?.cpf)}
                  maxLength={12}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Nacionalidade">Nacionalidade</Label>
                <Input
                  name="nationality"
                  onChange={setInput("nationality")}
                  value={form.nationality}
                />
              </S.divWrapper>
            </S.Column>
            <S.fullInput>
              <Label htmlFor="Profissao">Profissão</Label>
              <Input
                name="profession"
                onChange={setInput("profession")}
                value={form.profession}
              />
            </S.fullInput>
            <S.Column>
              <S.divWrapper>
                <Label htmlFor="CEP">CEP</Label>
                <Input
                  name="zip_code"
                  onChange={(e) => {
                    searchPostalCode(e.target.value);
                  }}
                  value={address.zip_code}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="Cidade">Cidade</Label>
                <Input
                  name="city"
                  onChange={(e) =>
                    setAddress({ ...address, city: e.target.value })
                  }
                  value={address.city}
                />
              </S.divWrapper>

              <S.divWrapper>
                <Label htmlFor="UF">Estado</Label>
                <Input
                  name="state"
                  onChange={(e) =>
                    setAddress({ ...address, state: e.target.value })
                  }
                  maxLength={2}
                  value={address.state}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Endereco">Endereço</Label>
              <Input
                name="street"
                onChange={(e) =>
                  setAddress({ ...address, street: e.target.value })
                }
                value={address.street}
              />
            </S.fullInput>

            <S.divWrapper>
              <Label htmlFor="Numero">Número</Label>
              <Input
                name="number"
                onChange={(e) =>
                  setAddress({ ...address, number: e.target.value })
                }
                value={address.number}
                type="number"
              />
            </S.divWrapper>

            <S.fullInput>
              <Label htmlFor="Bairro">Bairro</Label>
              <Input
                name="neighborhood"
                onChange={(e) =>
                  setAddress({ ...address, neighborhood: e.target.value })
                }
                value={address.neighborhood}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Complemento">Complemento</Label>
              <Input
                name="complement"
                onChange={(e) =>
                  setAddress({ ...address, complement: e.target.value })
                }
                value={address.complement}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="NomePai">Nome do Pai</Label>
              <Input
                name="father_name"
                onChange={setInput("father_name")}
                value={form.father_name}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="NomeMae">Nome da Mãe</Label>
              <Input
                name="mother_name"
                onChange={setInput("mother_name")}
                value={form.mother_name}
              />
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="Obs">Observação</Label>
              <TextField
                fullWidth
                multiline
                rows={4}
                maxRows={8}
                onChange={setInput("observation")}
                value={form.observation}
              />
            </S.fullInput>
            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerCustomers();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  navigate("/listagem-lojista");
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>

        <TabPanel value={1}>
          <S.Title>Dados Bancários</S.Title>

          <S.Form>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Banco</Label>
              <S.Select
                onChange={(e) =>
                  setFormBankInfo({ ...formBankInfo, bank_id: e.target.value })
                }
                value={formBankInfo.bank_id}
              >
                <option value="">Selecionar banco</option>
                {bankList.map((bank) => (
                  <option value={bank.id}>{bank?.name}</option>
                ))}
              </S.Select>
            </S.fullInput>

            <S.Column>
              <S.divWrapper>
                <Label htmlFor="Agência">Agência</Label>
                <Input
                  name="agency"
                  onChange={setInputBankInfo("agency")}
                  value={formBankInfo.agency}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="Conta Corrente">Conta Corrente</Label>
                <Input
                  name="account"
                  onChange={setInputBankInfo("account")}
                  value={formBankInfo.account}
                />
              </S.divWrapper>
              <S.divWrapper>
                <Label htmlFor="CPF">CPF</Label>
                <Input
                  name="document"
                  onChange={setInputBankInfo("document")}
                  value={
                    !!formBankInfo?.document
                      ? cpfMask(formBankInfo.document)
                      : ""
                  }
                  maxLength={12}
                />
              </S.divWrapper>
            </S.Column>

            <S.fullInput>
              <Label htmlFor="Nome do titular">Nome do titular</Label>
              <Input
                name="holder_name"
                onChange={setInputBankInfo("holder_name")}
                value={formBankInfo.holder_name}
              />
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerBank();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  navigate("/listagem-lojista");
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>

        <TabPanel value={2}>
          <S.uploadWrapper>
            <Label htmlFor="CNH">CNH - Frente</Label>
            {Array.isArray(fileCNHFront) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileCNHFront}
                onChange={setFileCNHFront}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileCNHFront}
                />
                <a href={fileCNHFront} target="_blank" rel="noreferrer">
                  {fileCNHFront}
                </a>
              </>
            )}
          </S.uploadWrapper>

          <S.uploadWrapper>
            <Label htmlFor="CNH">CNH - Verso</Label>
            {Array.isArray(fileCNHBack) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileCNHBack}
                onChange={setFileCNHBack}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileCNHBack}
                />
                <a href={fileCNHBack} target="_blank" rel="noreferrer">
                  {fileCNHBack}
                </a>
              </>
            )}
          </S.uploadWrapper>

          <S.uploadWrapper>
            <Label htmlFor="CNH">Foto - Selfie</Label>
            {Array.isArray(fileSelfie) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileSelfie}
                onChange={setFileSelfie}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileSelfie}
                />
                <a href={fileSelfie} target="_blank" rel="noreferrer">
                  {fileSelfie}
                </a>
              </>
            )}
          </S.uploadWrapper>

          <S.uploadWrapper>
            <Label htmlFor="Comprovante Endereço">Comprovante Endereço</Label>
            {Array.isArray(fileEndereco) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileEndereco}
                onChange={setFileEndereco}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileEndereco}
                />
                <a href={fileEndereco} target="_blank" rel="noreferrer">
                  {fileEndereco}
                </a>
              </>
            )}
          </S.uploadWrapper>

          <S.uploadWrapper>
            <Label htmlFor="Certidão de Casamento">Certidão de Casamento</Label>
            {Array.isArray(fileMarried) ? (
              <FileUpload
                title="Carregar arquivo"
                value={fileMarried}
                onChange={setFileMarried}
              />
            ) : (
              <>
                <FileUpload
                  title="Carregar arquivo"
                  value={null}
                  onChange={setFileMarried}
                />
                <a href={fileMarried} target="_blank" rel="noreferrer">
                  {fileMarried}
                </a>
              </>
            )}
          </S.uploadWrapper>

          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerDocument();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                navigate("/listagem-lojista");
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </TabPanel>
        <TabPanel value={3}>
          <S.Title>Taxas do Cliente</S.Title>
          <S.Form>
            <S.fullInput>
              <Label htmlFor="">Tabela de taxas Cartão de crédito</Label>
              <S.Select
                onChange={(e) =>
                  setTaxInfo((oldTaxInfo) => ({
                    ...oldTaxInfo,
                    credit_card: e.target.value,
                  }))
                }
                value={taxInfo.credit_card}
              >
                {taxTables?.credit_card?.map((table) => (
                  <option value={table?.id}>
                    {table?.billing_category_name}
                  </option>
                ))}
              </S.Select>
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Tabela de taxa Boleto</Label>
              <S.Select
                onChange={(e) =>
                  setTaxInfo((oldTaxInfo) => ({
                    ...oldTaxInfo,
                    boleto: e.target.value,
                  }))
                }
                value={taxInfo.boleto}
              >
                {taxTables?.boleto?.map((table) => (
                  <option value={table?.id}>
                    {table?.billing_category_name}
                  </option>
                ))}
              </S.Select>
            </S.fullInput>
            <S.fullInput>
              <Label htmlFor="CasaPropria">Tabela de taxa Pix</Label>
              <S.Select
                onChange={(e) =>
                  setTaxInfo((oldTaxInfo) => ({
                    ...oldTaxInfo,
                    pix: e.target.value,
                  }))
                }
                value={taxInfo.pix}
              >
                {taxTables?.pix?.map((table) => (
                  <option value={table?.id}>
                    {table?.billing_category_name}
                  </option>
                ))}
              </S.Select>
            </S.fullInput>

            <S.ButtonWrapper>
              <S.Button
                onClick={() => {
                  registerTaxes();
                }}
              >
                Salvar
              </S.Button>
              <S.OutlineButton
                onClick={() => {
                  navigate("/listagem-lojista");
                }}
              >
                Cancelar
              </S.OutlineButton>
            </S.ButtonWrapper>
          </S.Form>
        </TabPanel>
      </TabsUnstyled>
    </MainLayout>
  );
}

export default RegisterShopPhysical;
