import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CircularProgress, IconButton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import Cloud from "../../assets/images/iconCloud.svg";
import Label from "../../components/Label";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/index";
import ModalDetails from "./components/modal";
import ModalLastConfirmation from "./components/modalLastConfirmation";
import * as S from "./style";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function WithdrawalRequest() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalLastConfirmationIsOpen, setModalLastConfirmationIsOpen] =
    useState(false);
  const [data, setData] = useState([]);
  const [status, setStatus] = useState("");
  const [reason, setReason] = useState("");
  const [withdrawalData, setWithdrawalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openElem, setOpenElem] = useState(null);
  const [currentWithdrawal, setCurrentWithdrawal] = useState();
  const open = Boolean(anchorEl);

  const MySwal = withReactContent(Swal);

  const [body, setBody] = useState({
    type: "",
    content: "",
    start: "",
    end: "",
  });

  const handleClick = (event, ElementId) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(ElementId);
  };
  function handleClose() {
    setOpenElem(null);
    setAnchorEl(null);
  }

  function openModalDetails() {
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  async function getData() {
    try {
      setLoading(true);
      const res = await api.post("/v1/gateway/admin/withdraw/list", body);
      setWithdrawalData(res.data.data);

      setLoading(false);

      if (res.data.error === `true`) {
        MySwal.fire({
          icon: "error",
          title: "Erro!",
          text: "Tente novamente...",
        });
      }
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Erro!",
        text: "Tente novamente...",
      });
      console.log(err);
    }
  }

  async function sendData() {
    const bodySendData = {
      id: currentWithdrawal.id,
      status: status,
      decline_message: reason,
    };

    try {
      const res = await api.put(
        "/v1/gateway/admin/withdraw/status",
        bodySendData
      );
      setModalLastConfirmationIsOpen(false);
      if (res.data.error === false) {
        MySwal.fire({
          icon: "success",
          title: "Status alterado!",
          confirmButtonText: "ok!",
          preConfirm: () => {
            window.location.reload();
          },
        });
      }

      if (res.data.error === true) {
        MySwal.fire({
          icon: "error",
          title: "Erro!",
          text: res.data.message,
        });
      }
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Erro!",
        text: "Tente novamente...",
      });
      console.log(err);
    }
  }

  function HandleExport() {
    setLoading(true);
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(withdrawalData);

    XLSX.utils.book_append_sheet(wb, ws, "MySheet1");

    XLSX.writeFile(wb, "SolicitacoesDeSaque.xlsx");
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <MainLayout title="Solicitações de saque">
      <S.Header>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, start: e.target.value })}
        >
          <Label>Data Inicial</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>
        <S.TimeFilter
          onChange={(e) => setBody({ ...body, end: e.target.value })}
        >
          <Label>Data Final</Label>
          <TextField
            id="date"
            type="date"
            inputProps={{
              style: {
                padding: 10,
                height: 20,
                marginRight: 10,
              },
            }}
          />
        </S.TimeFilter>

        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="code">Código da transação</option>
          <option value="statustransaction">Status da transação</option>
          <option value="statusfinance">Status financeiro</option>
          <option value="name">Lojista</option>
          <option value="CPF">CPF/CNPJ</option>
          <option value="email">Email</option>
        </S.Select>
        <S.Input
          placeholder="Buscar"
          onChange={(e) => setBody({ ...body, content: e.target.value })}
        />
        <S.Button disabled={loading} onClick={() => getData()}>
          Pesquisar
        </S.Button>
      </S.Header>

      <S.HeaderButtons>
        <S.ButtonHeader disabled={loading} onClick={() => HandleExport()}>
          <S.ImgCloud src={Cloud} alt="icon"></S.ImgCloud>download csv
        </S.ButtonHeader>
      </S.HeaderButtons>

      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Data/Hora</TableCell>
                <TableCell>Lojista</TableCell>
                <TableCell>Valor Total</TableCell>
                <TableCell>Dados Bancários</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawalData.map((withdrawalData) => (
                <TableRow
                  key={withdrawalData.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {withdrawalData.id}
                  </TableCell>
                  <TableCell>{withdrawalData.customer.name}</TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(withdrawalData.amount)}
                  </TableCell>
                  <TableCell>
                    Agência: {withdrawalData.bank_account.agency} Conta:
                    {withdrawalData.bank_account.account} Proprietário:
                    {withdrawalData.bank_account.holder_name} Documento:
                    {withdrawalData.bank_account.document}
                  </TableCell>
                  <TableCell>
                    <S.Icon status={withdrawalData.status}>
                      <S.TextIcon status={withdrawalData.status}>
                        {withdrawalData.status}
                      </S.TextIcon>
                    </S.Icon>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      setData(withdrawalData);
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => handleClick(e, withdrawalData.id)}
                      style={{ padding: 0 }}
                    >
                      <MoreVertOutlinedIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={openElem === withdrawalData.id}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {withdrawalData.status === "aprovado" ? (
                        ""
                      ) : (
                        <MenuItem
                          onClick={() => {
                            setStatus("aprovado");
                            handleClose();
                            openModalDetails();
                            setCurrentWithdrawal(withdrawalData);
                          }}
                        >
                          Aprovar solicitação
                        </MenuItem>
                      )}

                      {withdrawalData.status === "reprovado" ? (
                        ""
                      ) : (
                        <MenuItem
                          onClick={() => {
                            setStatus("reprovado");
                            handleClose();
                            openModalDetails();
                            setCurrentWithdrawal(withdrawalData);
                          }}
                        >
                          Reprovar solicitação
                        </MenuItem>
                      )}

                      {withdrawalData.status === "cancelado" ? (
                        ""
                      ) : (
                        <MenuItem
                          onClick={() => {
                            setStatus("cancelado");
                            openModalDetails();
                            handleClose();
                            setCurrentWithdrawal(withdrawalData);
                          }}
                        >
                          Cancelar solicitação
                        </MenuItem>
                      )}
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      {modalIsOpen ? (
        <ModalDetails
          status={status}
          setModalIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          data={data}
          setReason={setReason}
          setModalLastConfirmationIsOpen={setModalLastConfirmationIsOpen}
        />
      ) : (
        ""
      )}

      {modalLastConfirmationIsOpen ? (
        <ModalLastConfirmation
          setModalLastConfirmationIsOpen={setModalLastConfirmationIsOpen}
          modalLastConfirmationIsOpen={modalLastConfirmationIsOpen}
          sendData={sendData}
          data={data}
          status={status}
        />
      ) : (
        ""
      )}
    </MainLayout>
  );
}
