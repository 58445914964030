import bannerPayhow from "../../assets/images/banner-payhow.png";
import CompanyPayhowLogo from "../../assets/images/payhow-logo.svg";
import Input from "../../components/Input";
import Label from "../../components/Label";
import * as S from "./style";
import api from "../../services/index";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Login() {
  const MySwal = withReactContent(Swal);

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  async function handleSubmit(e) {
    setIsDisabled(true);
    e.preventDefault();
    try {
      const res = await api.post("/v1/gateway/admin/login", {
        email: username,
        password,
      });
      if (res.data?.token) {
        localStorage.setItem("token", res?.data?.token);
        localStorage.setItem("id", res?.data?.id);
        setTimeout(() => {
          window.location.href = `/home`;
        }, [1000]);
      } else {
        MySwal.fire({
          icon: "error",
          title: "Login inválido!",
          text: "Tente novamente...",
        });
      }
      setIsDisabled(false);
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Login inválido!",
        text: "Tente novamente...",
      });
      setIsDisabled(false);
    }
  }

  return (
    <S.Container>
      <S.Wrapper>
        <img src={bannerPayhow} alt="Imagem Login" />

        <S.Content>
          <img src={CompanyPayhowLogo} alt="Logo empresa" />
          <p>Olá, Administradora seja bem vindo 👋🏻</p>

          <S.Form>
            <Label htmlFor="user">Usúario</Label>
            <Input
              id="user"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />

            <Label htmlFor="password">Senha</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <S.Button disabled={isDisabled} onClick={(e) => handleSubmit(e)}>
              ENTRAR
            </S.Button>
          </S.Form>

          <span>Powered by PAYHOW © 2022</span>
        </S.Content>
      </S.Wrapper>
    </S.Container>
  );
}

export default Login;
