import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import * as S from "./styles";
import Modal from "@mui/material/Modal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const style = {
  position: "absolute",
  borderRadius: "10px",
  width: `35%`,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,

  outline: `1px solid #d9d9d9`,
};

export default function ModalDetails({ modalIsOpen, setModalIsOpen, handleDeleteUser }) {
  const handleClose = () => setModalIsOpen(false);
  
  return (
    <Modal
      open={modalIsOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"

    >
      <Box sx={style}>
        <S.Icon>
          <CloseOutlinedIcon onClick={handleClose} />
        </S.Icon>

        <S.ContainerTitle>
          <S.Text>Tem certeza que deseja deletar esse usuario ?</S.Text>
        </S.ContainerTitle>

        <S.DivRow>
          <S.OutlineButton onClick={handleDeleteUser}>SIM</S.OutlineButton>
          <S.DefaultButton onClick={handleClose}>NAO</S.DefaultButton>
        </S.DivRow>
      </Box>
    </Modal>
  );
}
