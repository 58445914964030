import AddIcon from "@mui/icons-material/Add";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { CircularProgress, IconButton, Popover } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { Link } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services/index";
import { phoneMask } from "../../utils/masks";
import ModalDetails from "./components/modal";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as S from "./style";

export default function ListAdmin() {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openElem, setOpenElem] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [admin, setAdmin] = React.useState();
  const [adminID, setAdminID] = React.useState(null);
  const [body, setBody] = React.useState({
    type: "",
    content: "",
  });
  const open = Boolean(anchorEl);

  const handleClick = (event, elemId) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(elemId);
  };

  function openModal(adminID) {
    setAdminID(adminID);
    setModalIsOpen(true);
    setAnchorEl(null);
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };

  async function getAdmin() {
    try {
      setLoading(true);
      const res = await api.post("v1/gateway/admin/list", body);
  
      setAdmin(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }

  async function handleDeleteUser() {
    try {
      await api.delete(`v1/gateway/admin/delete/${adminID}`);

      setModalIsOpen(false);
      getAdmin();
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    getAdmin();
  }, []);

  return (
    <MainLayout title="Usuários de acesso">
      <S.Header>
        <S.Select onChange={(e) => setBody({ ...body, type: e.target.value })}>
          <option value="" selected>
            Selecionar filtro
          </option>
          <option value="name">Nome</option>
        </S.Select>

        <S.Input
          placeholder="Buscar administrador"
          onChange={(e) => setBody({ ...body, content: e.target.value })}
        />

        <S.Button disabled={loading} onClick={() => getAdmin()}>
          Pesquisar
        </S.Button>
      </S.Header>

      <S.HeaderButtons>
        <Link
          to={`/cadastro-usuario-acesso`}
          style={{ textDecoration: "none" }}
        >
          <S.ButtonHeader>
            <AddIcon></AddIcon>Novo Cadastro
          </S.ButtonHeader>
        </Link>
      </S.HeaderButtons>
      <TableContainer component={Paper}>
        {loading ? (
          <S.ContainerLoading>
            <CircularProgress style={{ color: "#07254f" }} />
          </S.ContainerLoading>
        ) : (
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#B2B2B2" }}>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>E-mail</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {admin?.map((admin) => (
                <TableRow
                  key={admin.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{admin.name}</TableCell>
                  <TableCell>{phoneMask(admin.phone)}</TableCell>
                  <TableCell>{admin.email}</TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? 'long-menu' : undefined}
                      aria-expanded={open ? 'true' : undefined}
                      aria-haspopup="true"
                      onClick={e => handleClick(e, admin.id)}
                    >
                      <MoreVertIcon />
                    </IconButton>

                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        'aria-labelledby': 'long-button',
                      }}
                      anchorEl={anchorEl}
                      open={openElem === admin.id}
                      onClose={handleClose}
                    >
                      <MenuItem>
                        <Link
                          to={`/cadastro-usuario-acesso`}
                          state={{ admin_id: admin.id }}
                        >
                          Editar Cadastro
                        </Link>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          openModal(admin.id);
                          handleClose();
                        }}
                      >
                        Deletar Cadastro
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <ModalDetails
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        handleDeleteUser={handleDeleteUser}
      />
    </MainLayout>
  );
}
