import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { styled } from "@mui/system";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import api from "../../services";
import * as S from "./style";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const Tab = styled(TabUnstyled)`
  color: #0092d4;
  font-size: 1rem;
  font-weight: bold;
  padding: 16px 40px;
  border: none;
  border-radius: 7px;
  background-color: #fff;
  cursor: pointer;

  &.${tabUnstyledClasses.selected} {
    background-color: #f4f4f4;
  }
`;

const initalUserPermission = [
  {
    id: 1,
    nivel: 1,
    slug: "historico_de_transacoes",
  },
  {
    id: 2,
    nivel: 1,
    slug: "taxas_e_tarifas",
  },
  {
    id: 3,
    nivel: 1,
    slug: "cadastro_lojista",
  },
  {
    id: 4,
    nivel: 1,
    slug: "cadastro_compradores",
  },
  {
    id: 5,
    nivel: 1,
    slug: "extrato_financeiro",
  },
  {
    id: 6,
    nivel: 1,
    slug: "execucao_pagamento",
  },
  {
    id: 7,
    nivel: 1,
    slug: "solicitacao_saque",
  },
  {
    id: 8,
    nivel: 1,
    slug: "estatisticas_payhow",
  },
  {
    id: 9,
    nivel: 1,
    slug: "cadastro_admin",
  },
];

const TabPanel = styled(TabPanelUnstyled)``;

const TabsList = styled(TabsListUnstyled)(() => `margin-bottom: 41px;`);
const MySwal = withReactContent(Swal);

const initialFormState = {
  name: "",
  phone: "",
  email: "",
  password: "",
};

function RegisterAdmin() {
  const navigate = useNavigate();
  const [formInfo, setFormInfo] = React.useState(initialFormState);
  const [adminId, setAdminId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [userPermission, setUserPermission] =
    React.useState(initalUserPermission);

  const user_id = localStorage.getItem("id");

  const { state } = useLocation();
  React.useEffect(() => {
    if (state) {
      getAdminInfo();
      setAdminId(state.admin_id);
    }

    return clearState();
  }, []);

  const getAdminInfo = async () => {
    try {
      setLoading(true);
      const res = await api.post("/v1/gateway/admin/detail", {
        admin_id: state.admin_id,
      });
      setFormInfo({
        name: res?.data.name,
        phone: res?.data.phone,
        email: res?.data.email,
      });
      setUserPermission((prevState) => {
        const newState = prevState.map((obj) => {
          let index = res?.data.permissions.findIndex(
            (item) => item.slug === obj.slug
          );
          if (res?.data.permissions[index]) {
            let nivel = Number(res?.data.permissions[index].nivel);
            return { ...obj, nivel: nivel };
          }
          return obj;
        });
        return newState;
      });
    } catch (err) {
      setLoading(false);
      console.log(err);
      MySwal.fire({
        icon: "error",
        title: "Erro!",
        text: "Tente novamente...",
      });
    }
  };

  const clearState = () => {
    setFormInfo(initialFormState);
    setAdminId(null);
    setLoading(false);
  };

  const handleFormInfo = (e) => {
    const { name, value } = e.target;
    setFormInfo((oldState) => ({ ...oldState, [name]: value }));
  };

  const registerUser = async () => {
    if (state) {
      try {
        setLoading(true);
        const res = await api.post("/v1/gateway/admin/register/permission", {
          ...state,
          modulos: userPermission,
        });

        if (res.data.error === true) {
          MySwal.fire({
            icon: "error",
            title: "Algo deu errado!",
            text: res.data.message,
            confirmButtonText: "ok!",
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Dados atualizados!",
            confirmButtonText: "ok!",
          }).then(function (isConfirm) {
            if (isConfirm) {
              window.location.href = `/usuario-acesso`;
            }
          });
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    } else {
      try {
        const res = await api.post("/v1/gateway/admin/register", {
          ...formInfo,
          modulos: userPermission,
        });
        if (res.data.error === true) {
          MySwal.fire({
            icon: "error",
            title: "Algo deu errado!",
            text: res.data.message,
            confirmButtonText: "ok!",
          });
        } else {
          MySwal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Dados atualizados!",
            confirmButtonText: "ok!",
          });
        }
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    }
  };

  function handleChangeCheckbox(event) {
    setUserPermission((prevState) => {
      const newState = prevState.map((obj) => {
        if (obj.id === Number(event.target.name)) {
          return { ...obj, nivel: event.target.checked === false ? 1 : 2 };
        }
        return obj;
      });
      return newState;
    });
  }

  return (
    <MainLayout title={"Cadastro de Lojistas"}>
      <TabsUnstyled defaultValue={0}>
        <TabsList>
          <Tab>Informações</Tab>
        </TabsList>

        <TabPanel value={0}>
          <S.Title>Informações</S.Title>
        </TabPanel>
        <S.Form>
          <S.fullInput>
            <S.Label htmlFor="name">Nome Completo</S.Label>
            <S.Input
              name="name"
              onChange={handleFormInfo}
              value={formInfo?.name}
            />
            <S.Label htmlFor="phone">Celular</S.Label>
            <S.Input
              name="phone"
              onChange={handleFormInfo}
              value={formInfo?.phone}
            />
            <S.Label htmlFor="email">E-mail de acesso</S.Label>
            <S.Input
              name="email"
              onChange={handleFormInfo}
              value={formInfo?.email}
            />
            <S.Label htmlFor="password">Senha de acesso</S.Label>
            <S.Input
              name="password"
              onChange={handleFormInfo}
              value={formInfo?.password}
            />
          </S.fullInput>

          <FormGroup
            sx={{ flexDirection: "row", flexWrap: "inherit", gap: "100px" }}
          >
            <S.FormWrapper>
              <FormControlLabel
                id="1"
                name="1"
                control={
                  <Checkbox
                    checked={Number(userPermission[0].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Autorizar, Reprovar e Cancelar transações"
              />
              {/* <FormControlLabel
                id='2'
                name='2'
                control={
                  <Checkbox                     
                    onChange={handleChangeCheckbox}
                  />} 
                label="Visualizar taxas e tarifas" 
              /> */}
              <FormControlLabel
                id="3"
                name="3"
                control={
                  <Checkbox
                    checked={Number(userPermission[2].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Cadastrar, editar e inativar lojistas"
              />
              <FormControlLabel
                id="4"
                name="4"
                control={
                  <Checkbox
                    checked={Number(userPermission[3].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Cadastrar, editar e inativar compradores"
              />
              <FormControlLabel
                id="5"
                name="5"
                control={
                  <Checkbox
                    checked={Number(userPermission[4].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Cadastrar e editar extrato financeiro"
              />
            </S.FormWrapper>
            <S.FormWrapper>
              <FormControlLabel
                id="6"
                name="6"
                control={
                  <Checkbox
                    checked={Number(userPermission[5].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Cadastrar e editar execução de pagamentos"
              />
              <FormControlLabel
                id="7"
                name="7"
                control={
                  <Checkbox
                    checked={Number(userPermission[6].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Aprovar, Reprovar e Cancelar saque"
              />
              {/* <FormControlLabel
                id='8'
                                                name='8'

                control={
                  <Checkbox                     
                    onChange={handleChangeCheckbox}
                  />} 
                label="Visualizar estatísticas" 
              /> */}
              <FormControlLabel
                id="9"
                name="9"
                control={
                  <Checkbox
                    checked={Number(userPermission[7].nivel) === 2}
                    onChange={handleChangeCheckbox}
                  />
                }
                label="Cadastrar e editar usuários"
              />
            </S.FormWrapper>
          </FormGroup>

          <S.ButtonWrapper>
            <S.Button
              onClick={() => {
                registerUser();
              }}
            >
              Salvar
            </S.Button>
            <S.OutlineButton
              onClick={() => {
                window.location.href = "/usuario-acesso";
              }}
            >
              Cancelar
            </S.OutlineButton>
          </S.ButtonWrapper>
        </S.Form>
      </TabsUnstyled>
    </MainLayout>
  );
}

export default RegisterAdmin;
