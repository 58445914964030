const internalStatus = (status) => {
  switch (status) {
    case "pending":
      return "pendente";
    case "paid":
      return "aprovado";
    case "cancelled":
      return "cancelado";
    case "refunded":
      return "estornado";
    case "chargeback":
      return "estornado";
    default:
      return status;
  }
};

export { internalStatus };
